import localForage from 'localforage';
import { BACKEND_CONFIG } from '../../configuration';
import { AuthTokens } from '../domain/entities/AuthTokens';

export const setTokensToFetch = (authTokens: AuthTokens | undefined) => {
  localForage.setItem('authTokens', authTokens);
};
export const removeTokensToFetch = () => {
  localForage.removeItem('authTokens');
};
export const getTokensToFetch = async () => (await localForage.getItem('authTokens')) as any;
export const HTTP_STATUS_INVALID_CREDENTIALS = 401;
export const HTTP_STATUS_WEAK_PRIVILEGES = 403;
export const HTTP_STATUS_NOT_FOUND = 404;
export const ACCOUNT_ALREADY_EXISTS = 422;

export const fetchWithToken = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  const authTokens: any = await localForage.getItem('authTokens');
  if (!authTokens.accessToken || !authTokens.renewToken) {
    throw new Error('no token found in the container in fetch helper');
  }
  const response: Response = await fetchV2(input.toString(), authTokens.accessToken, init?.method, init?.body);
  if (response.ok) {
    return response;
  }
  if (response.status === HTTP_STATUS_INVALID_CREDENTIALS || response.status === HTTP_STATUS_WEAK_PRIVILEGES) {
    const refreshTokensRes = await fetchV2(BACKEND_CONFIG.endpoints.renew, authTokens.renewToken, 'POST');
    if (refreshTokensRes.ok) {
      return refreshTokensRes.json().then(async (res) => {
        setTokensToFetch(res);
        const secondTryResponse: Response = await fetchV2(input.toString(), res.accessToken, init?.method, init?.body);
        return secondTryResponse;
      });
    }
    removeTokensToFetch();
    window.location.href = 'signIn';
  }

  const error = await response.json();
  return Promise.reject(new Error(response.status.toString(), { cause: error.message }));
};

export const fetchV2 = async (
  url: string,
  authorization?: string,
  method?: string,
  body?: BodyInit | null,
): Promise<Response> => {
  // if (ENV === 'dev') {
  //   console.log(`fetchV2 method: ${method ?? 'GET'} to ${url}`);
  //   console.log('fetchV2 authorization token exists:', !!authorization);
  //   console.log('fetchV2 body:', body);
  // }
  if (authorization === '') {
    throw new Error('no token found in the container in fetch helper');
  }
  const response = await fetch(url, {
    method: method ?? 'GET',
    headers: {
      ...(authorization && { Authorization: authorization }),
      Accept: 'application/json',
      'Accept-Encoding': 'gzip',
      'Content-Type': 'application/json',
    },
    body,
  });
  /* if (!response.ok) {
    const error = await response.json();
    throw new Error(response.status.toString(), { cause: error.message });
  } */
  return response;
};
