import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import React, { useEffect, useState } from 'react';
import { defaultPassword, hardcodedUsers, User } from '../../domain/entities/User';
import { Color, Padds, StyleSheet } from '../../../assets/styles/constantStyles';

interface AdminDevToolsProps {
  // connectedUser: User | undefined;
  loadUsersAsync(): Promise<DispatchAsyncResult<User[]>>;
  sendSignUpAsync(user: User): Promise<DispatchAsyncResult<User>>;
}
export const AdminDevToolsDumb = ({ loadUsersAsync, sendSignUpAsync }: AdminDevToolsProps) => {
  const [usersList, setUsersList] = useState<User[]>([]);

  useEffect(() => {
    reFreshUsers();
  }, []);

  const reFreshUsers = () => {
    loadUsersAsync()
      .then((res) => {
        if (!res.success) {
          alert(`error while executing loadUsersAsync\n${res.error}`);
          return;
        }
        setUsersList(res.result);
      })
      .catch((err) => {
        alert(`error while executing loadUsersAsync\n${err}`);
      });
  };

  const sendSignUp = (userToRegister: User) => {
    sendSignUpAsync({ ...userToRegister })
      .then((res) => {
        if (!res.success) {
          alert(`error while executing loadUsersAsync\n${res.error}`);
          return;
        }
        reFreshUsers();
      })
      .catch((err) => {
        alert(`error while executing loadUsersAsync\n${err}`);
      });
  };
  return (
    <div style={styles.container}>
      LIST OF USERS TO REGISTER: ({defaultPassword} (always!))
      {hardcodedUsers.map((user, i) => (
        <div
          key={`user-${i + 1}`}
          style={{
            flexDirection: 'row',
            width: '100%',
            flex: 1,
            display: 'flex',
            paddingBottom: '1em',
            alignItems: 'center',
          }}
        >
          <div style={{ flex: 1 }}>{user.email}</div>
          <div style={{ flex: 1 }}> {user.role}</div>

          <div
            onKeyDown={() => {
              sendSignUp(user);
            }}
            tabIndex={0}
            role="button"
            onClick={() => {
              sendSignUp(user);
            }}
            style={styles.btn}
          >
            Register new user
          </div>
          <br />
        </div>
      ))}
      <div onKeyDown={reFreshUsers} tabIndex={0} role="button" onClick={reFreshUsers} style={styles.btn}>
        Refresh users
      </div>
      LIST OF USERS:
      <div style={{ flex: 1, width: '100%' }}>
        {usersList.map((user) => (
          <div key={user.id}>{JSON.stringify(user, null, '\t')}</div>
        ))}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    margin: '1em',
  },
  btn: {
    background: Color.DANGER,
    padding: Padds.PADD1,
    width: 150,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
};
